import { getFormCategory, getFormCategoryByCheckActive } from 'Core/services/FormCategory';

export const GetFormCategoryByCheckActive=()=>dispatch=>{
    getFormCategoryByCheckActive().then(res => {
        dispatch({
            type: "GET_FORMCATEGORY_CHECKACTIVE",
            formCategoryData: res.data
        })
    })
        .catch((err) => {
            console.log(err)
        })
}

export const GetFormCategory=()=>dispatch=>{
    getFormCategory().then(res => {
        dispatch({
            type: "GET_FORMCATEGORY",
            formCategory: res.data
        })
    })
        .catch((err) => {
            console.log(err)
        })
}