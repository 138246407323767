const initialState = {
    subbranchData: [],
    subbranchmainData: [],
    subbranchidData:[]
}
const subbranchReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GetAllSubBranch":
            return { ...state, subbranchData: action.alldata };
        case "GetSubBranchsByMainId":
            return{...state, subbranchmainData: action.alldata }
        case "GetSubBranchsById":
            return{...state, subbranchidData: action.alldata }
        default:
            return state;
    }
}
export default subbranchReducers;