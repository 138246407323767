const initialState = {
    branchData: [],
    idbranchData: [],
    branchImage: [],

}
const branchReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GetAllMainBranch":
            return { ...state, branchData: action.alldata };
        case "GetBranchById":
            return{...state, idbranchData: action.alldata }
        case "GetBranchImages":
            return{...state, branchImage: action.alldata }
        default:
            return state;
    }
}
export default branchReducers;