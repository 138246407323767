const initialState = {
    detailNewsData: [],
}
const detailNewsReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GetNewsById":
            return{...state, detailNewsData: action.alldata }
        default:
            return state;
    }
}
export default detailNewsReducers;