import React, { useState, useEffect } from 'react';
import {
    Navbar,
    NavDropdown,
    Nav,
    Row, Col
} from 'react-bootstrap';
import { getallbranch } from '../../redux/Branchs/action';
import { useDispatch, useSelector } from 'react-redux';
import SecondNavbar from './SecondNavbar';
import logo from '../../assets/img/logotransparan.png';
import { GetFormCategoryByCheckActive } from 'redux/FormCategory/action';

const Header = () => {
    const dispatch = useDispatch();
    const store = useSelector(state => state.BranchReducers);
    const { formCategoryData } = useSelector(state => state.FormCategoryReducer);
    const [show, setShow] = useState(false);
    const showDropdown = (e) => {
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }
    const [show1, setShow1] = useState(false);
    const showDropdown1 = (e) => {
        setShow1(!show1);
    }
    const hideDropdown1 = e => {
        setShow1(false);
    }

    useEffect(() => {
        dispatch(getallbranch());
        dispatch(GetFormCategoryByCheckActive())
    }, [])

    useEffect(() => {
        const navbar = document.getElementById("myNavbar");
        const image = document.getElementById("headerIMG");
        const sticky = navbar.offsetTop + 50;
        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > sticky && window.innerWidth > 992) {
                navbar.classList.add("sticky");
                image.style.transform = "scale(.6)";
            }
            else {
                navbar.classList.remove("sticky");
                image.style.transform = "scale(1)";
            }
        })
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, []);

    return (
        <>
            <Row className="bsgutterim" >
                <Navbar id="myNavbar" style={{ backgroundColor: "#FFFFFF", position: "relative" }} fixed="top" className="navborder" expand="lg">
                    <Col className="firstCol" md="2" xs="6" lg="6" xl="7" >
                        <a className="text-center" href="/">
                            <img id="headerIMG" className="navbar-image" src={logo} alt="res" />
                        </a>
                    </Col>
                    <Col className="text-center" md="10" xs="6" lg="6" xl="5" >
                        <div className="nav">
                            <Navbar.Toggle className="navtoggle" aria-controls="navbarScroll" />
                            <Navbar.Collapse
                                className="tog mt-2" id="basic-navbar-nav">
                                <Nav className="me-auto">
                                    <Nav.Link className="link" href="/">Ana Sayfa</Nav.Link>
                                    <NavDropdown
                                        show={show}
                                        onMouseEnter={showDropdown}
                                        onMouseLeave={hideDropdown}
                                        title="Kulüp" id="basic-nav-dropdown">
                                        <NavDropdown.Item className="drop" href="/history">Tarihçe</NavDropdown.Item>
                                        <NavDropdown.Item className="drop" href="/management">Yönetim Kurulu</NavDropdown.Item>
                                        <NavDropdown.Item className="drop" href="/adminstrative">İdari Kadro</NavDropdown.Item>
                                        <NavDropdown.Item className="drop" href="/technical">Teknik Kadro</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link className="link" href="/news">Haberler</Nav.Link>
                                    <NavDropdown
                                        show={show1}
                                        onMouseEnter={showDropdown1}
                                        onMouseLeave={hideDropdown1}
                                        title="Branşlar" id="basic-nav-dropdown">
                                        {
                                            store.branchData.map((branch, key) => (
                                                <NavDropdown.Item key={key} href={`/branchs/${branch.id}`} className="drop" >{branch.name}</NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                    {formCategoryData &&
                                        <Nav.Link className="link" href="/application">Başvuru</Nav.Link>
                                    }
                                    <Nav.Link className="link" href="/contact">İletişim</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                    </Col>
                </Navbar >
            </Row>
            <div id="SecondBar">
                <SecondNavbar />
            </div>
        </>
    );
}

export default Header;