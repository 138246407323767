import React, {useEffect} from 'react';
import {Col, Row, Container} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone, faEnvelope, faCity, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import "./Footer.css";
import {SocialIcon} from 'react-social-icons';
import {getallbranch} from '../../redux/Branchs/action';
import {useDispatch, useSelector} from 'react-redux';
import superImage from '../../assets/img/super.png';
import sivasbellogo from '../../assets/img/sivasbellogo.png';
// import hilmibilginSignature from '../../assets/img/HilmiBilgin2.png';

const Footer = () => {
    const dispatch = useDispatch();
    const store = useSelector(state => state.BranchReducers);
    useEffect(() => {
        dispatch(getallbranch());

    }, [])

    useEffect(() => {
        const arrow = document.getElementById("footerDiv");
        const active = arrow.offsetTop + 200;
        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > active) {
                arrow.classList.add("active");
            } else {
                arrow.classList.remove("active")
            }
        })
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, [])

    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <div>
            <div color="mdb-color" className="font-small pt-4">
                <Container className="text-center text-md-left">
                    <Row className="text-center mt-1 pb-4">
                        <Col xs={12} sm={12} md={3} lg={3} xl={3} className="mt-3">
                            <h6 className="mb-4 header1 font-weight-bold">
                                Sivas Belediyespor
                            </h6>
                            <Row className="justify-content-center">
                                <Col style={{width: 60}} xs={6} sm={6} md={12} lg={6}>
                                    <img className="sivasbellogo" src={sivasbellogo}/>
                                </Col>&nbsp;&nbsp;
                                {/*<Col xs={6} sm={6} md={12} lg={6}>*/}
                                {/*    <img className="imza" src={hilmibilginSignature}/>*/}
                                {/*</Col>*/}
                            </Row>
                            <img className="sportoto" src={superImage}>
                            </img>
                        </Col>
                        <hr className="w-100 clearfix d-md-none"/>
                        <Col sm={6} md={3} lg={3} xl={3} className="hiddenKulüp mx-auto mt-3">
                            <div style={{textAlign: "center"}}>
                                <div style={{display: "inline-block", textAlign: "left"}}>
                                    <Row style={{justifyContent: "center", display: "contents"}}>
                                        <Col sm={12} md={12} lg={12} className="header1 mb-4 font-weight-bold">
                                            Kulüp
                                        </Col>
                                        <Col sm={12} md={12} lg={12}>
                                            <p>
                                                <a className="footerLink" href="/history">Tarihçe</a>
                                            </p>
                                            <p>
                                                <a className="footerLink" href="/management">Yönetim Kurulu</a>
                                            </p>
                                            <p>
                                                <a className="footerLink" href="/adminstrative">İdari Kadro</a>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={3} xl={3} className="mx-auto mt-3">
                            <div style={{textAlign: "center"}}>
                                <div style={{display: "inline-block", textAlign: "left"}}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12} className="header1 mb-4 font-weight-bold">
                                            Branşlar
                                        </Col>
                                        <Row>
                                            {
                                                store.branchData.map((branch, key) => (
                                                    <Col key={key} xs={6} sm={6} md={6} lg={6}>
                                                        <a key={key} className="navigate"
                                                           href={`/branchs/${branch.id}`}>{branch.name} </a>
                                                    </Col>
                                                ))
                                            }
                                        </Row>

                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <hr className="w-100 clearfix d-md-none"/>
                        <Col md={3} lg={3} xl={3} className="mx-auto mt-3">
                            <h6 className="header1 mb-4">İletişim</h6>
                            <div className="contactt">
                                <Row className="iletisim">
                                    <Col sm={2} md={2} lg={2}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} style={{color: "#399e68"}}/>
                                    </Col>
                                    <Col sm={10} md={10} lg={10}>
                                        <p>
                                            Yenişehir Mahallesi Sultan Şehir Bulvarı Muhsin
                                            Yazıcıoğlu Spor Kompleksi
                                        </p>
                                    </Col>

                                    <Col sm={2} md={2} lg={2}>
                                        <FontAwesomeIcon icon={faEnvelope} style={{color: "#399e68"}}/>
                                    </Col>
                                    <Col sm={10} md={10} lg={10}>
                                        <p>
                                            info@sivasbelediyespor.org
                                        </p>
                                    </Col>
                                    <Col sm={2} md={2} lg={2}>
                                        <FontAwesomeIcon icon={faPhone} style={{color: "#399e68"}}/>
                                    </Col>
                                    <Col sm={10} md={10} lg={10}>
                                        <p>
                                            +90 (346) 211 28 58
                                        </p>
                                    </Col>
                                    <Col sm={2} md={2} lg={2}>
                                        <FontAwesomeIcon icon={faCity} style={{color: "#399e68"}}/>
                                    </Col>
                                    <Col sm={10} md={10} lg={10}>
                                        <p>
                                            Sivas, TR
                                        </p>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className="text-center">
                                            <ul className="social">
                                                <li>
                                                    <a><SocialIcon className="socialIcon" fgColor="white"
                                                                   bgColor="#4267B2"
                                                                   url="https://facebook.com/sivasbelediyespor"/></a>
                                                    <a><SocialIcon className="socialIcon" fgColor="#F5F8FA"
                                                                   bgColor="#1DA1F2"
                                                                   url="https://twitter.com/sivasbldspor"/></a>
                                                    <a><SocialIcon className="socialIcon" fgColor="#F5F8FA"
                                                                   bgColor="#fb3958"
                                                                   url="https://instagram.com/sivasbelediyespor58"/></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className="d-flex align-items-center">
                        <Col sm={12} md={12} lg={12}>
                            <p className="text-center white-text">
                                &copy; {new Date().getFullYear()} Sivas Belediyespor. Tüm Hakları Saklıdır
                            </p>
                        </Col>
                    </Row>
                </Container>
                <div onClick={topFunction} id="footerDiv" className="go-top">
                    <svg className="go-top1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                         viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <line x1="12" y1="19" x2="12" y2="5">
                        </line>
                        <polyline points="5 12 12 5 19 12">
                        </polyline>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Footer;