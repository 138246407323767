import {StrictMode} from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './router/Index';
import {Provider} from 'react-redux'
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css'
import store from './redux/store'
import './assets/fonts/Poppins-Thin.ttf';
import { Suspense } from 'react';

const root = createRoot(document.getElementById('root'));
root.render(<StrictMode>
    <Provider store={ store}>
        <Suspense fallback={false}>
            <App/>
        </Suspense>
    </Provider>
</StrictMode>)

reportWebVitals();