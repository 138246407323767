import { combineReducers } from "redux";
import BranchReducers from '../redux/Branchs/reducer';
import AdministrativeReducer from '../redux/Adminstrative/reducer';
import AboutReducer from '../redux/History/reducer';
import SubbranchReducer from "../redux/SubBranch/reducer";
import ManagementReducer from "./Management/reducer";
import NewsReducers from '../redux/News/reducer';
import PlayerReducers from '../redux/Player/reducer';
import detailNewsReducers from "./DetailsNews/reducer";
import technicalStaffReducer from "./TechicalStaff/reducer";
import FormCategoryReducer from "./FormCategory/reducer";

const rootReducer = combineReducers({
    BranchReducers,
    AdministrativeReducer,
    AboutReducer,
    SubbranchReducer,
    ManagementReducer,
    NewsReducers,
    PlayerReducers,
    detailNewsReducers,
    technicalStaffReducer,
    FormCategoryReducer
});

export default rootReducer;