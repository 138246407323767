import BranchService from '../../Core/services/BranchService';

 const getallbranch = () => dispatch => {
    BranchService.branchService().then(res => {
        dispatch({
            type: "GetAllMainBranch",
            alldata: res.data
        })
    })
        .catch((err) => {
            console.log(err)
        })
}

const getBranchsById=(id)=>dispatch=>{
    BranchService.getBranchById(id).then(res => {
        dispatch({
            type: "GetBranchById",
            alldata: res.data
        })
    })
        .catch((err) => {
            console.log(err)
        })
}

const getBranchsImage=(id)=>dispatch=>{
    BranchService.getBranchImages(id).then(res => {
        dispatch({
            type: "GetBranchImages",
            alldata: res.data
        })
    })
        .catch((err) => {
            console.log(err)
        })
}

export {getallbranch,getBranchsById,getBranchsImage}