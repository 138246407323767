import React, { useEffect } from 'react';

const SidebarData = [

    {
        title: 'Anasayfa',
        path: '/',
        cName: 'nav-text'
    },
    {
        title: 'Kulüp',
        cName: 'nav-text',
        path: '#',
        subNav: [
            {
                title: 'Tarihçe',
                path: '/history',
            },
            {
                title: 'Yönetim Kurulu',
                path: '/management',
            },
            {
                title: 'İdari Kadro',
                path: '/adminstrative',
            },
            {
                title: 'Teknik Kadro',
                path: '/technical',
            },
        ]
    },
    {
        title: 'Haberler',
        path: '/news',
        cName: 'nav-text'
    },
    {
        title: 'Branşlar',
        cName: 'nav-text',
        path: '#',
        subNav: [
            {
                title: 'Futbol',
                path: `/branchs/${1}`,
            },
            {
                title: 'Hentbol',
                path: `/branchs/${2}`,
            },
            {
                title: 'Karate',
                path: `/branchs/${3}`,
            },
            {
                title: 'Taekwondo',
                path: `/branchs/${4}`,
            },
            {
                title: 'Sayokan',
                path: `/branchs/${5}`,
            },
            {
                title: 'Okçuluk',
                path: `/branchs/${6}`,
            },
            {
                title: 'Halk Oyunları',
                path: `/branchs/${7}`,
            },
        ]
    },
    {
        title: 'İletişim',
        path: '/contact',
        cName: 'nav-text'
    },
    {
        title: 'Başvuru',
        path: '/application',
        cName: 'nav-text'
    },
];





export { SidebarData }