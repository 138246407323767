const initialState = {
    newsData: [],newsPaginationsData:[]
}
const newsReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GetAllNews":
            return { ...state, newsData: action.alldata };
        case "GetAllNewsPaginations":
            return { ...state, newsPaginationsData: action.alldata };
        default:
            return state;
    }
}
export default newsReducers;