import React from 'react';
import { routes } from './Routes';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Header from '../components/Header/Header';
import '../components/Header/Header.css';
import Footer from '../components/Footer/Footer';

const Index = () => {
  return (
    <Router>
      <Header/>
      <Switch>
        {
          routes.map((item, key) => {
            return (
              <Route key={key} path={item.path} component={item.component}>
              </Route>
            )
          })
        }
      </Switch>
      <Footer/>
    </Router>
  )
}

export default Index;
