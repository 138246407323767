import axios from "axios";
import apiUrl from "../api";

const branchService = () => {
    return axios.get(`${apiUrl}/MainBranch/GetAllMainBranch`)
}

const getBranchById=(id)=>{
    return axios.get(`${apiUrl}/MainBranch/GetMainBranchById/${id}`)
}

const getBranchImages=(id)=>{
    
    return axios.get(`${apiUrl}/MainBranch/GetImageIdList/${id}`)
}



let BranchService = {
    branchService,getBranchById,getBranchImages
}

export default BranchService;