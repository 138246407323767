const initialState = {
    managementData:[],
}

const ManagementReducer = (state = initialState,action) => {     
    switch (action.type) {
        case "GetAllManagement":
            return {...state, managementData: action.alldata}
        default:
            return  state;
    }
}

export default ManagementReducer;