const initialState = {     
    aboutData:[]
}

const AboutReducer = (state = initialState, action) => {   
    switch (action.type) {
        case "GetAboutById":
            return {...state,aboutData:action.alldata}
        default:
            return state;
    }
}

export default AboutReducer;