const initialState = {
    technicalStaffList: []
}

const technicalStaffReducer = (state = initialState, action) => {
    switch (action.type) {
        case "TechnicalStaffList":
            return {
                ...state,
                technicalStaffList: action.technicalStaffList
            }

        default:
            return state;
    }
}

export default technicalStaffReducer;