import { lazy } from "react";

const routes=[
{
    path:"/contact",
    component: lazy(()=>import("../pages/Contact"))
},
{
    path:"/history",
    component: lazy(()=>import("../pages/History"))
},
{
    path:"/management",
    component: lazy(()=>import("../pages/Management"))
},
{
    path:"/technical",
    component: lazy(()=>import("../pages/Technical"))
},
{
    path:"/galery/:id",
    component: lazy(()=>import("../pages/Galery"))
},
{
    path:"/branchs/:id",
    component: lazy(()=>import("../pages/Branchs"))
},
{
    path:"/subbranchs/:id",
    component: lazy(()=>import("../pages/SubBranch"))
},
{
    path:"/adminstrative",
    component: lazy(()=>import("../pages/Adminstrative"))
},
{
    path:"/announce",
    component: lazy(()=>import("../pages/Announce"))
},
{
    path:"/application",
    component: lazy(()=>import("../pages/Application"))
},
{
    path:"/news",
    component: lazy(()=> import("../pages/News"))
},
{
    path:"/newsdetails/:id",
    component: lazy(() => import("../pages/DetailsNews"))
},
{
    path:"/",
    component: lazy(()=>import("../pages/Home")),
},
];

export  {routes}