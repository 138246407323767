const initialState = {
    formCetegoryData: [],
    formCetegory: []
}
const FormCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_FORMCATEGORY_CHECKACTIVE":
            return {
                ...state,
                formCategoryData: action.formCategoryData
            }
            case "GET_FORMCATEGORY":
            return {
                ...state,
                formCategory: action.formCategory
            }
        default:
            return state;
    }
}
export default FormCategoryReducer;