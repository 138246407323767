import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SubMenu.css';

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <div>
      <div className={item.title == 'Anasayfa' ? 'mt-0' : ''}>
      <a className="sideBarLink" href={item.path} onClick={item.subNav && showSubnav}>
        <div>
          {item.title} 
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened  
            : item.subNav 
              ? item.iconClosed
              : null}
        </div>
      </a>
      {subnav &&
        item.subNav?.map((item, index) => {
          return (
            
            <a className="dropDownLink" href={item.path} key={index}>
              {item.title}
            </a>
          );
        })}
    </div>
    </div>
  );
};

export default SubMenu;