const initialState = {
    administrativeData:[],
}

const AdministrativeReducer = (state = initialState, action) => {             
    switch (action.type) {
        case "GetAllAdministrative":
            return { ...state, administrativeData: action.alldata };
        default:
            return state;
    }
}

export default AdministrativeReducer;