import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import styled from 'styled-components';
import SubMenu from './SubMenu';
import './Navbar.css';
import { IconContext } from 'react-icons';
import sivasbel from '../../assets/img/sivasbellogo.png';
import hilmibilgin from '../../assets/img/HilmiBilgin.png'
import logo from '../../assets/img/logotransparan.png'
import yesillogo from '../../assets/img/whitelogo.png'

const Nav = styled.div`
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const NavIcon = styled(Link)`
  background: #fff;
  padding: 5px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  text-aling:center;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 2rem;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: linear-gradient(0deg, rgba(0,122,112,1) 30%, rgba(120,186,37,1) 100%);
  width: 250px;
  height: auto;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0 !important;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 550ms;
  z-index: 10;
//   overflow-y: scroll;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const SecondNavbar = () => {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    return (
        <div id="myNavbar2">
            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav>
                    <div className="location justify-content-start">
                        <a href="/"><img className="secondNavbarLogo" src={logo} /></a>
                    </div>

                    <NavIcon to='#'>

                        <FaIcons.FaBars onClick={showSidebar} />
                    </NavIcon>
                </Nav>
                <SidebarNav sidebar={sidebar}>
                    <SidebarWrap>
                    <img className="m-3" style={{height:"50px", width:"50px"}} src={yesillogo} />

                        <NavIcon to='#'>
                            
                            <AiIcons.AiOutlineClose onClick={showSidebar} />
                        </NavIcon>
                        <div></div>
                        <div className="sideBarTop">

                            {SidebarData.map((item, index) => {
                                return <SubMenu item={item} key={index} />;
                            })}
                        </div>
                        {/*<div className="sidaBarBottom">*/}
                        {/*    <div className="flex-container justify-content-center">*/}
                        {/*        <img style={{ width: "50%", position: "relative" }} src={hilmibilgin} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                    </SidebarWrap>

                </SidebarNav>

            </IconContext.Provider>
        </div>
    )


}
export default SecondNavbar;
